<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="row mb-2">
            <div class="col-md-12 text-start">
              <h3>Projects</h3>
            </div>
          </div>
          <div v-if="projects.length === 0 && !loading.page">
            <CenterMessage message="No Projects found."></CenterMessage>
          </div>
          <div v-else class="text-start">
            <b-table
              :responsive="true"
              small
              hover
              v-if="!loading.page"
              :items="projects"
              :fields="fields"
            >
              <template #cell(status)="data">
                <div>
                  {{ data.item.status.name }}
                </div>
              </template>
              <template #cell(hourly_rate)="data">
                <div>
                  <span v-if="data.item.hourly_rate"
                    ><span class="currency_code">GH₵</span>
                    <b> {{ data.item.hourly_rate }}</b>
                  </span>
                  <span v-else class="text-muted text-sm">Not set. </span>
                </div>
              </template>
              <template #cell(action)="data">
                <div>
                  <a :href="'/projects/view/' + data.item.id">View</a>
                </div>
              </template>
            </b-table>
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import CenterMessage from "@/components/CenterMessage.vue";
import ProjectService from "@/services/ProjectService";

export default {
  name: "ListProjectsView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: ["id", "name", "hourly_rate", "time_budget", "status", "action"],
      projects: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },

  async mounted() {
    await this.getProjects();
    this.loading.page = false;
  },

  methods: {
    async getProjects(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await ProjectService.getProjects(
        this.pagination.currentPage,
        this.pagination.perPage
      );

      this.projects = response.data.projects.data;
      this.pagination.totalRows = response.data.projects.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.projects.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getProjects();
      }
    },
  },
};
</script>