<template>
  <div>
    <div class="container login">
      <div class="row justify-content-center">
        <div class="col-md-3">
          <div class="row">
            <div>
              <img
                src="@/assets/logo-on-white-vertical-with-slogan.png"
                width="200"
                alt="Nusoft Group Logo - On Black"
              />
            </div>
            <div style="font-weight: 900; font-size: 18px">
              Client Portal Login
            </div>
          </div>
          <div class="mt-2 text-start">
            <b-alert :show="errors.length > 0" variant="danger">
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </b-alert>
            <b-alert :show="success != ''" variant="success">
              <ul>
                <li>{{ success }}</li>
              </ul>
            </b-alert>
          </div>

          <div class="row text-start mt-2">
            <b-form-group
              id="email-fieldset"
              label="Email"
              label-for="email-input"
            >
              <b-form-input
                size="sm"
                id="email-input"
                v-model="email"
                trim
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="mt-2"
              id="password-fieldset"
              label="Password"
              label-for="password-input"
            >
              <b-form-input
                size="sm"
                id="password-input"
                type="password"
                v-model="password"
              ></b-form-input>
            </b-form-group>
            <div class="text-end mt-3">
              <b-button
                size="sm"
                class="w-100 fw-bold"
                @click.prevent="onSubmit"
                variant="primary"
                :disabled="loading"
              >
                {{ loading ? "Loading, please wait..." : "Login" }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatErrors } from "@/utils/helpers";

export default {
  name: "LoginView",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      errors: {},
      success: "",
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;

      let action = "";
      let payload = {};
      action = "user/login";
      payload = {
        email: this.email,
        password: this.password,
      };

      // Process submission
      // eslint-disable-next-line no-unused-vars
      this.$store
        .dispatch(action, payload)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          let route = "";

          this.success = "Login successful.";

          // redirect to home or redirect route if set.
          if (this.$route.query.redirect) {
            route = this.$route.query.redirect;
          } else {
            route = "/tickets";
          }

          this.$router.push(route);
        })
        .catch((error) => {
          if (error.response.status === 422 || error.response.status === 401) {
            let scope = this;
            scope.errors = formatErrors(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.login {
  margin-top: 100px;
}

.form-text {
  font-size: 0.8rem;
}
</style>
