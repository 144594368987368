<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4 class="text-left">Project #{{ project.id }}</h4>
              <div class="row">
                <div class="col-md-12">
                  <div>
                    Status:
                    <b class="">{{ project.status.name }}</b>
                  </div>
                  <div>
                    Hourly Rate:
                    <b
                      ><span class="currency_code">GH₵ </span
                      >{{ project.hourly_rate }}</b
                    >
                  </div>
                  <div>
                    Time Budget:
                    <b>{{ project.time_budget }} </b>
                  </div>
                  <div>
                    Estimated Budget:
                    <b>
                      <span class="currency_code">GH₵ </span
                      >{{
                        (project.hourly_rate * project.time_budget).toFixed(2)
                      }}</b
                    >
                  </div>
                  <div>
                    Link:
                    <a
                      :href="project.description"
                      target="_blank"
                      rel="noopener noreferrer"
                      >View Project Details & Terms</a
                    >
                  </div>
                </div>
                <div
                  v-if="project.status_code == 'pending_execution_approval'"
                  class="col-md-12 mt-3"
                >
                  <div class="text-start">
                    <b-button
                      @click.prevent="approveProject"
                      size="md"
                      :disabled="loading.project"
                      type="button"
                      variant="success"
                      >Approve Project for Execution</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader.vue";
import ProjectService from "@/services/ProjectService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "ViewProjectView",
  components: {
    CustomLoader,
  },
  data() {
    return {
      loading: {
        page: true,
        project: false,
      },
      project: {},
      success: "",
      errors: [],
    };
  },

  async mounted() {
    await this.getProject();
    document.title = `Nusoft Group - Project #${this.project.id}`;
  },

  methods: {
    async getProject() {
      this.loading.page = true;
      let response = await ProjectService.getProject(this.$route.params.id);
      this.project = response.data.project;
      this.loading.page = false;
    },

    approveProject() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.project = true;

      // Submit form.
      ProjectService.approveProject(this.$route.params.id)
        .then((response) => {
          this.success = response.data.message;
          this.project = response.data.project;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.project = false;
        });
    },
  },
};
</script>

<style>
.row {
  margin-right: 0;
  margin-left: 0;
}
</style>