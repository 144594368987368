var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-4 text-start"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12"},[_c('h4',[_vm._v("Raise a New Ticket")]),_c('b-alert',{attrs:{"variant":"success","show":_vm.success !== ''}},[_vm._v(_vm._s(_vm.success))]),_c('b-alert',{attrs:{"variant":"danger","show":_vm.errors.length > 0}},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)]),_c('b-form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"required",attrs:{"id":"title-group","label":"Title","label-for":"title"}},[_c('b-form-input',{staticClass:"mb-3",attrs:{"disabled":this.loading.form,"id":"title","type":"text","placeholder":"Enter the ticket title.","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('b-form-group',{staticClass:"mb-3 mt-3",attrs:{"id":"site-group","label":"Site","label-for":"site"}},[_c('b-form-select',{staticClass:"w-100",attrs:{"disabled":this.loading.form,"id":"site","options":_vm.options.sites},model:{value:(_vm.form.site_id),callback:function ($$v) {_vm.$set(_vm.form, "site_id", $$v)},expression:"form.site_id"}})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"description-group","label":"Description","label-for":"description"}},[_c('Editor',{attrs:{"disabled":this.loading.form,"id":"update","api-key":"4rq3ibpfp8f38sdodmtvtrh8oczqt541wz9our5ey3o9xjlj","init":{
                    placeholder: 'Describe your request or issue here.',
                    height: 150,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat ',
                  }},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_c('b-form-file',{ref:"attachments",staticClass:"form-control mb-3 mt-2",attrs:{"placeholder":"Attach Files","multiple":"","plain":"","disabled":this.loading.form},model:{value:(_vm.form.files),callback:function ($$v) {_vm.$set(_vm.form, "files", $$v)},expression:"form.files"}}),_c('div',{staticClass:"text-end"},[_c('b-button',{attrs:{"size":"sm","disabled":this.loading.form,"type":"submit","variant":"primary"}},[_vm._v("Raise Ticket")])],1)],1)],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }