<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4 class="text-left">
                Service Level Agreement #{{ service_level_agreement.id }}
              </h4>
              <div class="row">
                <div class="col-md-12">
                  <div>
                    Status:
                    <b
                      v-if="service_level_agreement.status_code == 'active'"
                      class="text-success"
                      >{{ service_level_agreement.status.name }}</b
                    >
                    <b
                      v-if="service_level_agreement.status_code == 'expired'"
                      class="text-danger"
                      >{{ service_level_agreement.status.name }}</b
                    >
                    <b
                      v-if="service_level_agreement.status_code == 'pending'"
                      class="text-primary"
                      >{{ service_level_agreement.status.name }}</b
                    >
                  </div>
                  <div>
                    Monthly Amount:
                    <b
                      ><span class="currency_code">GH₵ </span
                      >{{ service_level_agreement.monthly_amount }}</b
                    >
                  </div>
                  <div
                    v-if="service_level_agreement.billing_period.discount > 0"
                  >
                    Discount:
                    <b
                      >{{
                        (
                          Math.round(
                            service_level_agreement.billing_period.discount *
                              100
                          ) / 100
                        ).toFixed(0)
                      }}
                      %</b
                    >
                  </div>
                  <div>
                    Monthly Due:
                    <b>
                      <span class="currency_code">GH₵ </span
                      >{{ service_level_agreement.monthly_payment }}</b
                    >
                  </div>
                  <div>
                    Billing Period:
                    <b>{{ service_level_agreement.billing_period.name }}</b>
                  </div>
                  <div>
                    Period Due:
                    <b class="text-success">
                      <span class="currency_code">GH₵ </span>
                      <span
                        v-if="
                          service_level_agreement.billing_period_code ==
                          'monthly'
                        "
                        >{{
                          numberWithCommas(
                            service_level_agreement.monthly_payment
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          service_level_agreement.billing_period_code ==
                          'quarterly'
                        "
                        >{{
                          numberWithCommas(
                            service_level_agreement.monthly_payment * 3
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          service_level_agreement.billing_period_code ==
                          'bi_yearly'
                        "
                        >{{
                          numberWithCommas(
                            service_level_agreement.monthly_payment * 6
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          service_level_agreement.billing_period_code ==
                          'yearly'
                        "
                        >{{
                          numberWithCommas(
                            service_level_agreement.monthly_payment * 12
                          )
                        }}</span
                      >
                    </b>
                  </div>
                  <div>
                    Next Due:
                    <b>{{
                      moment(service_level_agreement.next_billing_date).format(
                        "dddd MMMM Do, YYYY."
                      )
                    }}</b>
                  </div>
                  <div>
                    Link:
                    <a
                      :href="service_level_agreement.sla_document_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      >View Service Level Agreement Document</a
                    >
                  </div>
                </div>
                <div
                  v-if="service_level_agreement.status_code == 'pending'"
                  class="col-md-12 mt-3"
                >
                  <div class="text-start">
                    <b-button
                      @click.prevent="approveSLA"
                      size="md"
                      :disabled="loading.sla"
                      type="button"
                      variant="success"
                      >Approve Service Level Agreement</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader.vue";
import SLAService from "@/services/SLAService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "ViewSLAView",
  components: {
    CustomLoader,
  },
  data() {
    return {
      loading: {
        page: true,
        sla: false,
      },
      service_level_agreement: {},
      success: "",
      errors: [],
    };
  },

  async mounted() {
    await this.getSLA();
    document.title = `Nusoft Group - SLA #${this.service_level_agreement.id}`;
  },

  methods: {
    async getSLA() {
      this.loading.page = true;
      let response = await SLAService.getSLA(this.$route.params.id);
      this.service_level_agreement = response.data.service_level_agreement;
      this.loading.page = false;
    },

    approveSLA() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.sla = true;

      // Submit form.
      SLAService.approveSLA(this.$route.params.id)
        .then((response) => {
          this.success = response.data.message;
          this.service_level_agreement = response.data.service_level_agreement;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.sla = false;
        });
    },
  },
};
</script>

<style>
.row {
  margin-right: 0;
  margin-left: 0;
}
</style>