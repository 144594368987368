<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-3 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>Change Your Password</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form
                class="mt-3"
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
              >
                <b-form-group
                  id="current-password-group"
                  label="Current Password"
                  label-for="current-password"
                  class="required"
                >
                  <b-form-input
                    :disabled="this.loading.form"
                    class="mb-3"
                    id="current-password"
                    v-model="form.current_password"
                    type="password"
                    placeholder="Enter your current password."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="new-password-group"
                  label="New Password"
                  label-for="new-password"
                  class="required"
                >
                  <b-form-input
                    :disabled="this.loading.form"
                    class="mb-3"
                    id="new-password"
                    v-model="form.new_password"
                    type="password"
                    placeholder="Enter your new password."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="confirm-new-password-group"
                  label="Confirm New Password"
                  label-for="confirm-new-password"
                  class="required"
                >
                  <b-form-input
                    :disabled="this.loading.form"
                    class="mb-3"
                    id="confirm-new-password"
                    v-model="form.new_password_confirmation"
                    type="password"
                    placeholder="Re-enter your new password."
                    required
                  ></b-form-input>
                </b-form-group>

                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="this.loading.form"
                    type="submit"
                    variant="primary"
                    >Change Password</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import UserService from "@/services/UserService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "RaiseTicketView",
  components: {},
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      success: "",
      errors: [],
    };
  },

  async mounted() {
    document.title = `Nusoft Group - Change Your Password`;
    this.loading.page = false;
  },

  methods: {
    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      UserService.changePassword({
        current_password: this.form.current_password,
        new_password: this.form.new_password,
        new_password_confirmation: this.form.new_password_confirmation,
      })
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          console.log(error);
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.current_password = "";
      this.form.new_password = "";
      this.form.new_password_confirmation = "";
    },
  },
};
</script>
  
  