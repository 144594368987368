import { ApiService } from "./APIService";

export default {
    getTickets(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null,
        statusCode = null,
        isPrivate = null
    ) {
        // Generate url
        let url = 'api/client/tickets?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        if (statusCode) {
            url += '&status_code=' + statusCode;
        }

        if (isPrivate) {
            url += '&is_private=' + isPrivate;
        }

        return ApiService.get(url);
    },

    getTicket(ticket_id) {
        return ApiService.get("api/client/tickets/" + ticket_id);
    },

    raiseTicket(payload) {
        return ApiService.post("api/client/tickets/raise", payload);
    },

    updateTicket(ticket_id, payload) {
        return ApiService.post("api/client/tickets/" + ticket_id + "/update", payload);
    },

    approveQuote(ticket_id) {
        return ApiService.post("api/client/tickets/" + ticket_id + "/approve");
    },

    getCategories() {
        return ApiService.get("api/client/tickets/categories");
    },
}