var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading.page)?_c('CustomLoader',{attrs:{"center":true}}):_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-4 text-start"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12"},[_c('h4',{staticClass:"text-left"},[_vm._v(" Service Level Agreement #"+_vm._s(_vm.service_level_agreement.id)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',[_vm._v(" Status: "),(_vm.service_level_agreement.status_code == 'active')?_c('b',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.service_level_agreement.status.name))]):_vm._e(),(_vm.service_level_agreement.status_code == 'expired')?_c('b',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.service_level_agreement.status.name))]):_vm._e(),(_vm.service_level_agreement.status_code == 'pending')?_c('b',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.service_level_agreement.status.name))]):_vm._e()]),_c('div',[_vm._v(" Monthly Amount: "),_c('b',[_c('span',{staticClass:"currency_code"},[_vm._v("GH₵ ")]),_vm._v(_vm._s(_vm.service_level_agreement.monthly_amount))])]),(_vm.service_level_agreement.billing_period.discount > 0)?_c('div',[_vm._v(" Discount: "),_c('b',[_vm._v(_vm._s(( Math.round( _vm.service_level_agreement.billing_period.discount * 100 ) / 100 ).toFixed(0))+" %")])]):_vm._e(),_c('div',[_vm._v(" Monthly Due: "),_c('b',[_c('span',{staticClass:"currency_code"},[_vm._v("GH₵ ")]),_vm._v(_vm._s(_vm.service_level_agreement.monthly_payment))])]),_c('div',[_vm._v(" Billing Period: "),_c('b',[_vm._v(_vm._s(_vm.service_level_agreement.billing_period.name))])]),_c('div',[_vm._v(" Period Due: "),_c('b',{staticClass:"text-success"},[_c('span',{staticClass:"currency_code"},[_vm._v("GH₵ ")]),(
                        _vm.service_level_agreement.billing_period_code ==
                        'monthly'
                      )?_c('span',[_vm._v(_vm._s(_vm.numberWithCommas( _vm.service_level_agreement.monthly_payment )))]):_vm._e(),(
                        _vm.service_level_agreement.billing_period_code ==
                        'quarterly'
                      )?_c('span',[_vm._v(_vm._s(_vm.numberWithCommas( _vm.service_level_agreement.monthly_payment * 3 )))]):_vm._e(),(
                        _vm.service_level_agreement.billing_period_code ==
                        'bi_yearly'
                      )?_c('span',[_vm._v(_vm._s(_vm.numberWithCommas( _vm.service_level_agreement.monthly_payment * 6 )))]):_vm._e(),(
                        _vm.service_level_agreement.billing_period_code ==
                        'yearly'
                      )?_c('span',[_vm._v(_vm._s(_vm.numberWithCommas( _vm.service_level_agreement.monthly_payment * 12 )))]):_vm._e()])]),_c('div',[_vm._v(" Next Due: "),_c('b',[_vm._v(_vm._s(_vm.moment(_vm.service_level_agreement.next_billing_date).format( "dddd MMMM Do, YYYY." )))])]),_c('div',[_vm._v(" Link: "),_c('a',{attrs:{"href":_vm.service_level_agreement.sla_document_link,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("View Service Level Agreement Document")])])]),(_vm.service_level_agreement.status_code == 'pending')?_c('div',{staticClass:"col-md-12 mt-3"},[_c('div',{staticClass:"text-start"},[_c('b-button',{attrs:{"size":"md","disabled":_vm.loading.sla,"type":"button","variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.approveSLA.apply(null, arguments)}}},[_vm._v("Approve Service Level Agreement")])],1)]):_vm._e()])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }