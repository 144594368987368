<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="row mb-2">
            <div class="col-md-12 text-start">
              <h3>Service Level Agreements</h3>
            </div>
          </div>
          <div v-if="service_level_agreements.length === 0 && !loading.page">
            <CenterMessage
              message="No Service Level Agreements found."
            ></CenterMessage>
          </div>
          <div v-else class="text-start">
            <b-table
              :responsive="true"
              small
              hover
              v-if="!loading.page"
              :items="service_level_agreements"
              :fields="fields"
            >
              <template #cell(status)="data">
                <div class="fw-bold">
                  <span
                    v-if="data.item.status.code == 'active'"
                    class="text-success"
                    >{{ data.item.status.name }}</span
                  >
                  <span
                    v-if="data.item.status.code == 'pending'"
                    class="text-primary"
                    >{{ data.item.status.name }}</span
                  >
                  <span
                    v-if="data.item.status.code == 'expired'"
                    class="text-danger"
                    >{{ data.item.status.name }}</span
                  >
                </div>
              </template>
              <template #cell(frequency)="data">
                <div>
                  {{ data.item.billing_period.name }}
                </div>
              </template>
              <template #cell(amount)="data">
                <div class="fw-bold">
                  <span class="currency_code">GH₵ </span>
                  <span v-if="data.item.billing_period_code == 'monthly'">{{
                    numberWithCommas(data.item.monthly_payment)
                  }}</span>
                  <span v-if="data.item.billing_period_code == 'quarterly'">{{
                    numberWithCommas(data.item.monthly_payment * 3)
                  }}</span>
                  <span v-if="data.item.billing_period_code == 'bi_yearly'">{{
                    numberWithCommas(data.item.monthly_payment * 6)
                  }}</span>
                  <span v-if="data.item.billing_period_code == 'yearly'">{{
                    numberWithCommas(data.item.monthly_payment * 12)
                  }}</span>
                </div>
              </template>
              <template #cell(next_payment_date)="data">
                <div>
                  <span
                    >{{
                      moment(data.item.next_billing_date).format(
                        "dddd MMMM Do YYYY"
                      )
                    }}
                  </span>
                </div>
              </template>
              <template #cell(action)="data">
                <div>
                  <a :href="'/service-level-agreements/view/' + data.item.id"
                    >View</a
                  >
                </div>
              </template>
            </b-table>
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import CenterMessage from "@/components/CenterMessage.vue";
import SLAService from "@/services/SLAService";

export default {
  name: "ListSLAView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "id",
        "status",
        "amount",
        "frequency",
        "next_payment_date",
        "action",
      ],
      service_level_agreements: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },

  async mounted() {
    await this.getSLAs();
    this.loading.page = false;
  },

  methods: {
    async getSLAs(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await SLAService.getSLAs(
        this.pagination.currentPage,
        this.pagination.perPage
      );

      this.service_level_agreements =
        response.data.service_level_agreements.data;
      this.pagination.totalRows = response.data.service_level_agreements.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages =
        response.data.service_level_agreements.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getSLAs();
      }
    },
  },
};
</script>