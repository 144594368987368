<template>
  <div id="app">
    <div>
      <b-navbar
        v-if="$store.getters['user/user']"
        toggleable="lg"
        type="light"
        variant="light"
        class="mb-5"
      >
        <div class="container">
          <b-navbar-brand href="/">
            <img
              src="@/assets/logo-client-portal-on-white-horizontal.png"
              height="35"
              alt="Nusoft Group Client Portal Logo - On White"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="/tickets">Tickets</b-nav-item>
              <b-nav-item href="/projects">Projects</b-nav-item>
              <b-nav-item href="/invoices">Invoices</b-nav-item>
              <b-nav-item href="/service-level-agreements">Service Level Agreements</b-nav-item>
              <b-nav-item-dropdown style="padding-left: 0">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  {{ $store.getters["user/user"].email }}
                </template>
                <b-dropdown-item href="/settings/password/change"
                  >Change Password</b-dropdown-item
                >
                <b-dropdown-item href="" @click.prevent="logout()"
                  ><span style="color: red !important"
                    >Sign Out</span
                  ></b-dropdown-item
                >
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
    <router-view />
    <div style="margin-bottom: 100px"></div>
    <footer class="fixed-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6" style="font-size: 12px">
            © Nusoft Group
            {{ new Date().getFullYear() != "2024" ? "2024 - " : "" }}
            {{ new Date().getFullYear() }}
            <span v-if="$store.getters['user/user']"
              >| {{ $store.getters["user/user"].email }} -
              <a href="" @click.prevent="logout" style="color: red"
                >Logout</a
              ></span
            >
          </div>
          <div class="col-md-6" style="font-size: 12px">
            Need help with this system? Call <b>059 701 8036</b>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      this.$router.push("/login");
    },
  },
};
</script>


<style>
@import "./assets/styles/main.css";
.dropdown-menu {
  padding-left: 0px !important;
}

.dropdown-item {
  font-size: 0.8rem !important;
}
</style>
