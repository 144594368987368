import { ApiService } from "./APIService";

export default {
    getSLAs(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/client/service-level-agreements?';

        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';
        
        return ApiService.get(url);
    },

    getSLA(sla_id) {
        return ApiService.get("api/client/service-level-agreements/" + sla_id);
    },

    approveSLA(sla_id) {
        return ApiService.patch("api/client/service-level-agreements/" + sla_id, {});
    },
}