<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="row mb-2">
            <div class="col-md-12 text-start">
              <h4>
                T{{
                  ticket.id + " - " + ticket.client.name + " - " + ticket.title
                }}
                {{ ticket.site_id != null ? " - " + ticket.site.url : "" }}
                {{ " - " + ticket.status.name }}
              </h4>
            </div>
            <div class="col-md-12 text-start">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <div>
                    <div class="ng-card">
                      <b-alert
                        class="mb-2"
                        variant="success"
                        :show="success !== '' && update.type_code == 'message'"
                        >{{ success }}</b-alert
                      >
                      <b-alert
                        class="mb-2"
                        variant="danger"
                        :show="
                          errors.length > 0 && update.type_code == 'message'
                        "
                      >
                        <ul>
                          <li v-for="error in errors" :key="error">
                            {{ error }}
                          </li>
                        </ul>
                      </b-alert>
                      <b-form @submit.prevent="onSubmit('message')">
                        <b-form-group id="update-group" label-for="update">
                          <Editor
                            :disabled="
                              this.loading.form ||
                              ['completed', 'cancelled'].includes(
                                this.ticket.status_code
                              )
                            "
                            id="update"
                            v-model="update.message"
                            api-key="4rq3ibpfp8f38sdodmtvtrh8oczqt541wz9our5ey3o9xjlj"
                            :init="{
                              placeholder: 'Enter your update here.',
                              height: 200,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat ',
                            }"
                          />
                        </b-form-group>
                        <b-form-file
                          class="form-control mb-2 mt-2"
                          v-model="update.files"
                          placeholder="Attach Files"
                          multiple
                          plain
                          ref="attachments"
                          :disabled="
                            this.loading.form ||
                            ['completed', 'cancelled'].includes(
                              this.ticket.status_code
                            )
                          "
                        ></b-form-file>

                        <div class="text-end mt-1">
                          <b-button
                            size="sm"
                            :disabled="
                              this.loading.form ||
                              ['completed', 'cancelled'].includes(
                                this.ticket.status_code
                              )
                            "
                            type="submit"
                            variant="primary"
                            >Update Ticket</b-button
                          >
                        </div>
                      </b-form>
                    </div>
                  </div>
                  <div>
                    <div
                      v-for="(update, index) in ticket.updates"
                      :key="update.id"
                      class="ng-card mt-3"
                    >
                      <div class="update-user-name mt-2">
                        {{ update.user.name + " - " }}
                        {{
                          update.user.type_code == "staff"
                            ? "Nusoft Group"
                            : ticket.client.name
                        }}
                      </div>
                      <div class="update-date">
                        {{
                          moment(update.created_at).format(
                            "dddd MMMM Do YYYY, h:mm a"
                          )
                        }}
                      </div>
                      <p
                        :class="
                          ['message', 'add_quote', 'update_quote'].includes(
                            update.type_code
                          )
                            ? ''
                            : 'text-muted mb-0 font-italic'
                        "
                        :style="
                          ['message', 'add_quote', 'update_quote'].includes(
                            update.type_code
                          )
                            ? ''
                            : 'font-style: italic'
                        "
                      >
                        <span v-html="update.message"></span>
                      </p>
                      <div v-if="update.attachments.length > 0" class="mb-2">
                        <span class="update-date">Attachments</span>

                        <div
                          style="font-size: 0.7rem; line-height: 1.3"
                          v-for="attachment in update.attachments"
                          :key="attachment.id"
                        >
                          <a
                            :href="attachment.file.storage_link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span
                              ><b-icon icon="paperclip" variant="dark"></b-icon>
                              {{ attachment.file.name }}</span
                            >
                          </a>
                        </div>
                      </div>
                      <div
                        class="text-end"
                        v-if="
                          ['add_quote', 'update_quote'].includes(
                            update.type_code
                          ) &&
                          index == 0 &&
                          ticket.is_budget_approved == 0 &&
                          !['completed', 'cancelled'].includes(
                            ticket.status_code
                          )
                        "
                      >
                        <b-button
                          @click.prevent="approveQuote"
                          size="sm"
                          :disabled="loading.form"
                          variant="success"
                          >Approve Quote</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import TicketService from "@/services/TicketService";
import { formatErrors } from "@/utils/helpers";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "ViewTicketView",
  components: {
    CustomLoader,
    Editor,
  },
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      ticket: {},
      update: {
        message: "",
        files: [],
      },
      success: "",
      errors: [],
    };
  },

  async mounted() {
    await this.getTicket(this.$route.params.id);
    document.title = `Nusoft Group - T${this.ticket.id} - ${this.ticket.title}`;
    this.loading.page = false;
  },

  methods: {
    async getTicket(ticketId) {
      this.loading.page = true;
      let response = await TicketService.getTicket(ticketId);
      this.ticket = response.data.ticket;
      this.update.status_code = this.ticket.status_code;
      this.update.category_code = this.ticket.category_code;

      this.loading.page = false;
    },

    async onSubmit(type_code) {
      this.update.type_code = type_code;

      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Build payload
      const formData = new FormData();
      formData.append("message", this.update.message);
      formData.append("is_private", this.update.is_private);
      if (this.update.files) {
        for (let i = 0; i < this.update.files.length; i++) {
          let file = this.$refs["attachments"].files[i];
          formData.append("files[" + i + "]", file);
        }
      }

      // Submit form.
      TicketService.updateTicket(this.ticket.id, formData)
        .then((response) => {
          this.success = response.data.message;
          this.ticket = response.data.ticket;
          this.update.message = "";
          this.update.files = [];
          this.$refs["attachments"].files = [];
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    async approveQuote() {
      // Set loading state
      this.loading.form = true;

      // Approve Quote
      TicketService.approveQuote(this.ticket.id)
        .then((response) => {
          this.success = response.data.message;
          this.ticket = response.data.ticket;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>