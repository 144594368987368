<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4 class="text-center">Invoice #{{ invoice.id }}</h4>
              <div class="row">
                <b-alert variant="success" :show="success !== ''">{{
                  success
                }}</b-alert>
                <b-alert variant="danger" :show="errors.length > 0">
                  <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </b-alert>
              </div>

              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <div class="row mb-2">
                  <div
                    class="col-md-6"
                    style="
                      padding-left: 0px;
                      padding-right: 0px;
                      line-height: 1.2;
                    "
                  >
                    <div class="invoice-summary-label mb-1">
                      {{ invoice.type == "client" ? "To" : "From" }} :
                    </div>
                    <div class="fw-bold">
                      {{
                        invoice.type == "client"
                          ? invoice.client.name
                          : invoice.staff.name
                      }}
                    </div>
                    <div>
                      <span class="invoice-summary-label">Date : </span>
                      <span class="invoice-summary-value"
                        >{{
                          moment(invoice.created_at).format(
                            "dddd MMMM Do YYYY, h:mm a"
                          )
                        }}
                      </span>
                    </div>
                    <div>
                      <span class="invoice-summary-label">Status : </span>
                      <span
                        class="invoice-summary-value text-danger"
                        v-if="invoice.status_code == 'pending'"
                        >UNPAID
                      </span>
                      <span
                        class="invoice-summary-value text-warning"
                        v-if="invoice.status_code == 'part_paid'"
                        >PART PAID
                      </span>
                      <span
                        class="invoice-summary-value text-success"
                        v-if="invoice.status_code == 'paid'"
                        >PAID
                      </span>
                      <span
                        class="invoice-summary-value text-dark"
                        v-if="invoice.status_code == 'cancelled'"
                        >CANCELLED
                      </span>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                  <div
                    class="col-md-4"
                    style="padding: 0; margin-top: auto; margin-bottom: 10px"
                  ></div>
                </div>
                <div class="row invoice-item-header text-start">
                  <div class="col-md-1 text-center">Id</div>
                  <div class="col-md-8" style="padding: 0px">Item</div>
                  <div class="col-md-1 text-center">Hrs.</div>
                  <div class="col-md-1 text-center">Rate</div>
                  <div class="col-md-1 text-center">Amt.</div>
                </div>
                <div
                  class="row invoice-item mt-2"
                  v-for="(lineItem, index) in this.invoice.items"
                  :key="lineItem.id"
                >
                  <div class="col-md-1 fw-bold text-center">
                    {{ index + 1 }}
                  </div>
                  <div class="col-md-8">
                    {{ lineItem.description }}
                  </div>
                  <div class="col-md-1 text-center">
                    {{ lineItem.quantity }}
                  </div>
                  <div class="col-md-1 text-center">
                    {{ numberWithCommas(lineItem.rate) }}
                  </div>
                  <div class="col-md-1 text-center mr-1 fw-bold">
                    {{ numberWithCommas(lineItem.amount) }}
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-5" style="padding: 0">
                    <div>
                      <div v-if="invoice.notes">
                        <b> Notes</b>
                        <div style="font-size: 0.8rem">{{ invoice.notes }}</div>
                      </div>
                      <span v-else class="text-muted text-sm">No notes. </span>
                    </div>
                    <div class="mt-3">
                      <span style="font-weight: 400;">Payment Methods</span>
                      <div style="font-size: 0.9rem" class="mt-1">
                        <b>Guaranty Trust Bank (GT Bank)</b> <br />
                        Bank SWIFT: <b>GTBIGHAC</b> <br />
                        Account No: <b>225123506110</b> <br />
                        Account Name: <b>Nusoft Group LTD</b> <br />
                        Currency: <b>GHS, Ghana Cedi</b> <br />
                      </div>

                      <div
                        v-if="invoice.total <= 2000"
                        style="font-size: 0.9rem"
                        class="mt-3 mb-0"
                      >
                        <span class="text-muted" style="font-size: 0.8rem"
                          ><em
                            >* Available
                            <b>
                              for amounts below
                              <span class="currency_code">GH₵</span> 2000</b
                            >
                          </em></span
                        >
                        <br />
                        <b>MTN Mobile Money</b> <br />
                        Account No: <b>0538837279</b> <br />
                        Account Name: <b>Nusoft Group LTD</b> <br />
                        Currency: <b>GHS, Ghana Cedi</b> <br />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3" style="padding: 0"></div>
                  <div class="col-md-4" style="padding: 0">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Sub-Total</div>
                      </div>
                      <div class="col-md-7 text-end invoice-summary-value">
                        <span class="currency_code">GH₵</span>
                        <b> {{ numberWithCommas(invoice.sub_total) }}</b>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Tax Amount</div>
                      </div>
                      <div class="col-md-7 text-end invoice-summary-value">
                        <span class="currency_code">GH₵</span>
                        <b> {{ numberWithCommas(invoice.tax) }}</b>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Total</div>
                      </div>
                      <div class="col-md-7 text-end invoice-summary-total">
                        <span class="currency_code">GH₵</span>
                        <b> {{ numberWithCommas(invoice.total) }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader.vue";
import InvoiceService from "@/services/InvoiceService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "ViewInvoiceView",
  components: {
    CustomLoader,
  },
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      invoice: {},
      success: "",
      errors: [],
    };
  },

  async mounted() {
    await this.getInvoice();
    document.title = `Nusoft Group - Invoice #${this.invoice.id} - GHS ${this.invoice.total}`;
  },

  methods: {
    async getInvoice() {
      this.loading.page = true;
      let response = await InvoiceService.getInvoice(this.$route.params.id);
      this.invoice = response.data.invoice;
      this.loading.page = false;
    },

    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      InvoiceService.updateInvoice(this.$route.params.id, {
        status_code: "cancelled",
      })
        .then((response) => {
          this.success = response.data.message;
          this.invoice = response.data.invoice;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>

<style>
.row {
  margin-right: 0;
  margin-left: 0;
}
</style>