var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading.page)?_c('CustomLoader',{attrs:{"center":true}}):_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6 text-start"},[_c('h3',[_vm._v("Tickets")])]),_c('div',{staticClass:"col-md-6 text-end"},[_c('a',{attrs:{"href":"/tickets/raise"}},[_c('b-button',{staticClass:"fw-bold",attrs:{"size":"sm","variant":"primary"}},[_vm._v(" Raise Ticket ")])],1)])]),(_vm.tickets.length === 0 && !_vm.loading.page)?_c('div',[_c('CenterMessage',{attrs:{"message":"No tickets found."}})],1):_c('div',{staticClass:"text-start"},[(!_vm.loading.page)?_c('b-table',{attrs:{"responsive":true,"small":"","hover":"","items":_vm.tickets,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('div',{class:'text-nowrap' +
                  (data.item.status_code == 'completed' ||
                  data.item.status_code == 'cancelled'
                    ? ' text-muted'
                    : '')},[_vm._v(" "+_vm._s(data.item.id)+" ")])]}},{key:"cell(category)",fn:function(data){return [_c('div',{class:'text-nowrap' +
                  (data.item.status_code == 'completed' ||
                  data.item.status_code == 'cancelled'
                    ? ' text-muted'
                    : '')},[(data.item.category_code != null)?_c('span',[_vm._v(_vm._s(data.item.category.name)+" ")]):_c('span',{staticClass:"text-muted text-sm"},[_vm._v("Not set. ")])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{class:'text-nowrap' +
                  (data.item.status_code == 'completed' ||
                  data.item.status_code == 'cancelled'
                    ? ' text-muted'
                    : '')},[_vm._v(" "+_vm._s(data.item.status.name)+" ")])]}},{key:"cell(last_updated)",fn:function(data){return [_c('div',{class:'text-nowrap' +
                  (data.item.status_code == 'completed' ||
                  data.item.status_code == 'cancelled'
                    ? ' text-muted'
                    : '')},[_vm._v(" "+_vm._s(_vm.moment(data.item.updates[0].created_at).format( "dddd MMMM Do YYYY, h:mm a" ))+" ")])]}},{key:"cell(title)",fn:function(data){return [_c('div',{class:data.item.status_code == 'completed' ||
                  data.item.status_code == 'cancelled'
                    ? ' text-muted'
                    : ''},[_vm._v(" "+_vm._s(data.item.title)+" ")])]}},{key:"cell(action)",fn:function(data){return [_c('div',[_c('a',{attrs:{"href":'/tickets/view/' + data.item.id}},[_vm._v("View")])])]}}],null,false,216598463)}):_vm._e(),_c('nav',[_c('ul',{staticClass:"pagination justify-content-center"},[(_vm.pagination.currentPage > 1)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#","aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage - 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]):_vm._e(),_vm._l((_vm.pagination.totalPages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{ active: page == _vm.pagination.currentPage }},[(
                    page < 3 ||
                    page > _vm.pagination.totalPages - 3 ||
                    (page < _vm.pagination.currentPage + 3 &&
                      page > _vm.pagination.currentPage - 3)
                  )?_c('a',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v(_vm._s(page))]):_vm._e()])}),(_vm.pagination.currentPage < _vm.pagination.totalPages)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"aria-label":"Next"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage + 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]):_vm._e()],2)])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }