import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

import LoginView from '@/views/LoginView.vue'

import ListTicketsView from '@/views/tickets/ListTicketsView.vue';
import RaiseTicketView from '@/views/tickets/RaiseTicketView.vue';
import ViewTicketView from '@/views/tickets/ViewTicketView.vue';

import ListInvoicesView from '@/views/invoices/ListInvoicesView.vue';
import ViewInvoiceView from '@/views/invoices/ViewInvoiceView.vue';

import NotFoundView from '@/views/NotFoundView.vue';
import ChangePasswordView from '@/views/settings/ChangePasswordView.vue';
import ViewSLAView from '@/views/service-level-agreements/ViewSLAView.vue';
import ListSLAView from '@/views/service-level-agreements/ListSLAView.vue';
import ListProjectsView from '@/views/projects/ListProjectsView.vue';
import ViewProjectView from '@/views/projects/ViewProjectView.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    name: '404',
    component: NotFoundView,
    meta: {
      title: 'Resource Not Found.',
      public: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login',
      public: true
    }
  },
  {
    path: '/',
    name: 'tickets',
    component: ListTicketsView,
    meta: {
      title: 'Tickets',
    }
  },
  {
    path: '/tickets/raise',
    name: 'add-ticket',
    component: RaiseTicketView,
    meta: {
      title: 'Raise Ticket',
    }
  },
  {
    path: '/tickets',
    name: 'list-tickets',
    component: ListTicketsView,
    meta: {
      title: 'Tickets',
    }
  },
  {
    path: '/tickets/view/:id',
    name: 'view-ticket',
    component: ViewTicketView,
    meta: {
      title: 'View Ticket',
    }
  },
  {
    path: '/projects/view/:id',
    name: 'view-project',
    component: ViewProjectView,
    meta: {
      title: 'View Project',
    }
  },
  {
    path: '/projects',
    name: 'list-projects',
    component: ListProjectsView,
    meta: {
      title: 'Projects',
    }
  },
  {
    path: '/service-level-agreements/view/:id',
    name: 'view-sla',
    component: ViewSLAView,
    meta: {
      title: 'View Service Level Agreement',
    }
  },
  {
    path: '/service-level-agreements',
    name: 'list-sla',
    component: ListSLAView,
    meta: {
      title: 'Service Level Agreements',
    }
  },
  {
    path: '/invoices',
    name: 'list-invoices',
    component: ListInvoicesView,
    meta: {
      title: 'Invoices',
    }
  },
  {
    path: '/invoices/view/:id',
    name: 'view-invoice',
    component: ViewInvoiceView,
    meta: {
      title: 'View Invoice',
    }
  },
  {
    path: '/settings/password/change',
    name: 'change-password',
    component: ChangePasswordView,
    meta: {
      title: 'Change Password',
    }
  },
]

const DEFAULT_TITLE = 'Nusoft Group';

const router = new VueRouter({
  mode: 'history',
  routes
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = 'Nusoft Group - ' + to.meta.title || DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  const authenticatedUser = store.getters["user/user"];
  const openRoute = to.matched.some((record) => record.meta.public);
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  const homeQuery = { path: "/tickets" };

  if (!openRoute && !authenticatedUser) {
    next(loginQuery);
  } else if (openRoute && authenticatedUser) {
    next(homeQuery);
  } else {
    next();
  }
});

export default router
