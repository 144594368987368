var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading.page)?_c('CustomLoader',{attrs:{"center":true}}):_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12 text-start"},[_c('h4',[_vm._v(" T"+_vm._s(_vm.ticket.id + " - " + _vm.ticket.client.name + " - " + _vm.ticket.title)+" "+_vm._s(_vm.ticket.site_id != null ? " - " + _vm.ticket.site.url : "")+" "+_vm._s(" - " + _vm.ticket.status.name)+" ")])]),_c('div',{staticClass:"col-md-12 text-start"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mt-2"},[_c('div',[_c('div',{staticClass:"ng-card"},[_c('b-alert',{staticClass:"mb-2",attrs:{"variant":"success","show":_vm.success !== '' && _vm.update.type_code == 'message'}},[_vm._v(_vm._s(_vm.success))]),_c('b-alert',{staticClass:"mb-2",attrs:{"variant":"danger","show":_vm.errors.length > 0 && _vm.update.type_code == 'message'}},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error)+" ")])}),0)]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit('message')}}},[_c('b-form-group',{attrs:{"id":"update-group","label-for":"update"}},[_c('Editor',{attrs:{"disabled":this.loading.form ||
                            ['completed', 'cancelled'].includes(
                              this.ticket.status_code
                            ),"id":"update","api-key":"4rq3ibpfp8f38sdodmtvtrh8oczqt541wz9our5ey3o9xjlj","init":{
                            placeholder: 'Enter your update here.',
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat ',
                          }},model:{value:(_vm.update.message),callback:function ($$v) {_vm.$set(_vm.update, "message", $$v)},expression:"update.message"}})],1),_c('b-form-file',{ref:"attachments",staticClass:"form-control mb-2 mt-2",attrs:{"placeholder":"Attach Files","multiple":"","plain":"","disabled":this.loading.form ||
                          ['completed', 'cancelled'].includes(
                            this.ticket.status_code
                          )},model:{value:(_vm.update.files),callback:function ($$v) {_vm.$set(_vm.update, "files", $$v)},expression:"update.files"}}),_c('div',{staticClass:"text-end mt-1"},[_c('b-button',{attrs:{"size":"sm","disabled":this.loading.form ||
                            ['completed', 'cancelled'].includes(
                              this.ticket.status_code
                            ),"type":"submit","variant":"primary"}},[_vm._v("Update Ticket")])],1)],1)],1)]),_c('div',_vm._l((_vm.ticket.updates),function(update,index){return _c('div',{key:update.id,staticClass:"ng-card mt-3"},[_c('div',{staticClass:"update-user-name mt-2"},[_vm._v(" "+_vm._s(update.user.name + " - ")+" "+_vm._s(update.user.type_code == "staff" ? "Nusoft Group" : _vm.ticket.client.name)+" ")]),_c('div',{staticClass:"update-date"},[_vm._v(" "+_vm._s(_vm.moment(update.created_at).format( "dddd MMMM Do YYYY, h:mm a" ))+" ")]),_c('p',{class:['message', 'add_quote', 'update_quote'].includes(
                          update.type_code
                        )
                          ? ''
                          : 'text-muted mb-0 font-italic',style:(['message', 'add_quote', 'update_quote'].includes(
                          update.type_code
                        )
                          ? ''
                          : 'font-style: italic')},[_c('span',{domProps:{"innerHTML":_vm._s(update.message)}})]),(update.attachments.length > 0)?_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"update-date"},[_vm._v("Attachments")]),_vm._l((update.attachments),function(attachment){return _c('div',{key:attachment.id,staticStyle:{"font-size":"0.7rem","line-height":"1.3"}},[_c('a',{attrs:{"href":attachment.file.storage_link,"target":"_blank","rel":"noopener noreferrer"}},[_c('span',[_c('b-icon',{attrs:{"icon":"paperclip","variant":"dark"}}),_vm._v(" "+_vm._s(attachment.file.name))],1)])])})],2):_vm._e(),(
                        ['add_quote', 'update_quote'].includes(
                          update.type_code
                        ) &&
                        index == 0 &&
                        _vm.ticket.is_budget_approved == 0 &&
                        !['completed', 'cancelled'].includes(
                          _vm.ticket.status_code
                        )
                      )?_c('div',{staticClass:"text-end"},[_c('b-button',{attrs:{"size":"sm","disabled":_vm.loading.form,"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.approveQuote.apply(null, arguments)}}},[_vm._v("Approve Quote")])],1):_vm._e()])}),0)])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }